import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import styled from "@emotion/styled"

import { CategoryMain } from "../styles/Layout"
import {
  FeaturedImage,
  HeroContent,
} from "../components/Category/CategoryElements"
import Breadcrumb from "../components/Breadcrumb/index"
import { StyledPostContent } from "../components/Article/ArticleElements"
import Seo from "../components/seo"
import Newsletter from "../components/Newsletter/index"
import ShareIcons from "../utils/ShareIcons"

const AboutPageWrapper = styled(StyledPostContent)`
  grid-column: center-start / center-end;
  margin: 3.2rem auto;
  max-width: 110rem;
  padding-top: 4.8rem;
  position: relative;
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  @media screen and (max-width: 70em) {
    flex-direction: column;
    align-content: center;
  }
`

const AboutAside = styled.aside`
  flex: 0 100000 25rem;
  position: sticky;
  top: 14.8rem;
  max-height: calc(-14.8rem + 100vh);
  overflow: auto;
  padding-bottom: 1.6rem;
  margin-left: auto;
  margin-top: 0.4rem;

  p {
    color: var(--grey-7);
    margin-top: 1rem;
  }

  @media screen and (max-width: 70em) {
    position: initial;
    max-height: 100%;
    margin-left: 0;
    overflow: initial;
  }
`

const AboutContent = styled.article`
  flex: 1 1 68.6rem;

  /* Fancy Drop Cap */
  & > p:nth-of-type(1)::first-letter {
    float: left;
    font-family: serif;
    font-size: 9rem;
    font-weight: 700;
    line-height: 6rem;
    opacity: 0.85;
    padding-left: 3px;
    padding-right: 8px;
    padding-top: 7px;
  }

  max-width: min(68.6rem, 100%);
`

const AboutNav = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;

  a {
    &:link,
    &:visited {
      color: initial;
      text-decoration: initial;
    }

    &:hover,
    &:active {
      opacity: 0.75;
    }
  }

  @media screen and (max-width: 70em) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  & > * {
    list-style: none;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`

const getAboutPageQuery = graphql`
  query MyQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }

    contentfulAboutPage {
      title
      slug
      seoDescription
      summary {
        childMarkdownRemark {
          html
        }
      }
      featuredImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          aspectRatio: 1.777777
          quality: 80
        )
        description
      }
      overview {
        childMarkdownRemark {
          html
        }
      }
      contentPhoto {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          quality: 80
          aspectRatio: 1
          width: 200
          height: 200
        )
        description
      }
    }
  }
`

const About = () => {
  const data = useStaticQuery(getAboutPageQuery)
  const { site } = data
  const {
    title,
    slug,
    seoDescription,
    summary,
    featuredImage,
    overview,
    contentPhoto,
  } = data.contentfulAboutPage

  const featuredImg = getImage(featuredImage)
  const featuredImgSrc = getSrc(featuredImage)
  const contentImg = getImage(contentPhoto)
  // const contentImgSrc = getSrc(contentPhoto)

  return (
    <>
      <Seo
        title={title}
        description={seoDescription}
        image={featuredImgSrc}
        imageAlt={featuredImage?.description || title}
        article={false}
      />
      <CategoryMain id="main-content">
        <FeaturedImage>
          <GatsbyImage
            image={featuredImg}
            alt={featuredImage?.description || title}
            style={{
              height: "59vh",
              gridColumn: "1/-1",
              gridRow: "1/-1",
            }}
          />
          <Breadcrumb
            className=""
            categoryTitle={title}
            categoryLink={slug}
            // subCategoryTitle={data.subCategory?.title}
            // subCategoryLink={`/${data.subCategory?.slug}`}
          />
          <HeroContent>
            <h1 className="bg-grad">{title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: summary.childMarkdownRemark.html,
              }}
            />
          </HeroContent>
        </FeaturedImage>

        <AboutPageWrapper>
          <AboutAside>
            <GatsbyImage
              image={contentImg}
              alt={contentPhoto.description}
              style={{
                // height: "59vh",
                gridColumn: "1/-1",
                gridRow: "1/-1",
              }}
            />
            <p>
              ENOCH ANTWI{" "}
              <span style={{ display: "block" }}>
              Managing Editor, <br />
              The Business Frontal
              </span>{" "}
            </p>
            <nav>
              <AboutNav style={{ margin: 0 }}>
                <li>
                  <Link to="/entrepreneurship/">- Entrepreneurship</Link>
                </li>
                <li>
                  <Link to="/innovative-technology/innovation/">
                    - Innovation
                  </Link>
                </li>
                <li>
                  <Link to="/innovative-technology/technology">
                    - Technology
                  </Link>
                </li>
                <li>
                  <Link to="/entrepreneurship/startups/">- Startups</Link>
                </li>
                <li>
                  <Link to="/corporate/">- Corporate</Link>
                </li>
                <li>
                  <Link to="/money/finance/">- Finance</Link>
                </li>
                <li>
                  <Link to="/money/investing/">- Investments</Link>
                </li>
                <li>
                  {" "}
                  <Link to="/health/">- Health &amp; Fitness</Link>
                </li>
              </AboutNav>
            </nav>
          </AboutAside>
          <AboutContent
            dangerouslySetInnerHTML={{
              __html: overview.childMarkdownRemark.html,
            }}
          />
          <div
            style={{
              marginInline: "auto",
            }}
          >
            <ShareIcons url={`${site.siteMetadata.siteUrl}/${slug}/`} />
          </div>
        </AboutPageWrapper>
        <Newsletter />
      </CategoryMain>
    </>
  )
}

export default About
